/* Base styling for HTML and Body */
html,
body {
  height: 100%;
  margin: 0;
}

/* Styling for root container */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content styling */
.main {
  flex: 1;
  padding: 30px;
  padding-top: 50px; /* Normalde 75'di anlık çözüm için 50px yaptım */
  padding-left: 15px; /* Adjusted left padding */
  background-color: white;
  color: #321c3d;
  font-family: 'Roboto', sans-serif;
}

/* Styling for the brand section */
.brand {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  align-self: flex-start; /* Aligns only the brand div to the left */
}

.brand h4 {
  margin: 0;
  padding-right: 1px;
}

.brand img {
  height: 1em;
}

/* Styling for slogan */
.slogan h1 {
  margin: 0;
}

/* Styling for shake image section */
.shakeimgs {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  padding-top: 50px;
}

.shakeimgs img {
  width: 400px;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #ddd;
}

/* Styling for start button */
.startbutton {
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 70px;
  display: flex;
  justify-content: center;
}

.startbutton button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #321c3d;
  color: white;
  border: none;
  border-radius: 15px;
}

.startbutton button:hover {
  background-color: #543e5e;
}

/* Footer styling other pages */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px 0;
  font-size: 12px;
  z-index: 1000;
}

.footer h4, .footer a, .footer p {
  color: #333;
  margin: 5px 0;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-info {
  margin-top: 10px;
}

/* Footer styling welcome page */
.footerw {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px 0;
  font-size: 12px;
  z-index: 1000;
}

.footerw h4, .footerw a, .footerw p {
  color: #333;
  margin: 5px 0;
  text-decoration: none;
}

.footerw a:hover {
  text-decoration: underline;
}

.footerw-info {
  margin-top: 10px;
}

/* Styling for social media icons */
.social-media-icons a {
  display: inline-block;
  margin: 0 10px;
}

.social-media-icons img {
  width: 24px;
  height: auto;
}

/* Styling for shake screen */
.shake-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.shake-now h3 {
  font-size: 2rem;
  margin-bottom: 40px;
}

.pleaseshake {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pleaseshake img {
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
}

/* Styling for word title */
.word-title {
  margin: 0;
  font-size: 1.5rem;
}

/* Styling for progress container */
.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #321c3d;
  color: white;
  border-radius: 10px;
  margin: 1vw;
  overflow-x: auto;
  width: calc(100% - 2vw);
  padding: 0.5vw;
}

.progress-step {
  padding: 0.5vw;
  font-size: 1.5vw;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #432e4f;
  margin: 0 0.5vw;
  transition: background-color 0.3s ease;
}

.progress-arrow {
  font-size: 1.5vw;
}

.progress-step.active {
  background-color: white;
  color: #321c3d;
}

/* Styling for Next and Back navigation buttons */
.buttons-container {
  position: absolute;
  right: 5px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.next-button,
.back-button {
  background-color: #321c3d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.next-button:hover,
.back-button:hover {
  background-color: #432e4f;
  transform: scale(1.05);
}

.back-button {
  background-color: lightgrey;
  color: black;
}

.back-button:hover {
  background-color: grey;
}

/* End of Next and Back buttons styling */

/* Styling for small word information */
.small-word-info {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.small-word-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.small-word-text {
  line-height: 1;
  font-size: 16px;
}

/* End of small word info styling */

/* Responsive design adjustments for max-width 800px */
@media (max-width: 800px) {
  .shakeimgs {
    padding: 5px;
    padding-top: 50px;
  }

  .shakeimgs img {
    width: calc(50% - 10px);
    height: auto;
  }
}

/* Responsive design adjustments for max-width 600px */
@media (max-width: 600px) {
  .progress-step,
  .progress-arrow {
    font-size: 2.0vw;
  }
}

/* CSS for PulledTheWord.js component */
.centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.centered-container img {
  max-width: 113px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 20px;
}

.buttons-container {
  position: absolute;
  right: 5px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.learn-button,
.skip-button {
  background-color: #321c3d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.learn-button:hover,
.skip-button:hover {
  background-color: #432e4f;
  transform: scale(1.05);
}

.skip-button {
  background-color: lightgrey;
  color: black;
}

.skip-button:hover {
  background-color: grey;
}

.info-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.part-of-speech,
.difficulty-level {
  margin: 2px 0;
  padding: 3px 10px;
  border-radius: 5px;
}

/* CSS for general structure in Definition.js */
.general-centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 10px;
}

.top-row-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.left-word-info,
.right-word-info {
  display: flex;
  align-items: center;
}

.general-small-word-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.word-title {
  font-size: 16px;
  margin-right: 10px;
}

.definition-section {
  text-align: center;
}

.word-definition {
  margin-top: 10px;
}

.general-buttons-container {
  position: absolute;
  right: 5px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.general-learn-button,
.general-skip-button {
  background-color: #321c3d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.general-learn-button:hover,
.general-skip-button:hover {
  background-color: #432e4f;
  transform: scale(1.05);
}

.general-skip-button {
  background-color: lightgrey;
  color: black;
}

.general-skip-button:hover {
  background-color: grey;
}

.general-info-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.general-part-of-speech,
.general-difficulty-level {
  margin: 2px 0;
  padding: 3px 10px;
  border-radius: 5px;
}

/* CSS for YourSentence.js */
.your-sentence-container {
  padding: 20px;
}

.sentence-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sentence-input:focus {
  outline: none;
  border-color: #321c3d;
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.submit-button {
  background-color: #321c3d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #432e4f;
}