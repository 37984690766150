/* desktop css file here desktop.css */


/* hide as default alert div */
.desktop-only-alert {
    display: none;
}

/* ------ */

/* showing desktop size the alert screen */
@media (min-width: 1024px) {

    /* hide app div for desktop */
    .welcome-screen {
        display: none;
    }

    .main {
        display: none;
    }

    /* ------ */

    .desktop-only-alert {
        padding: 60px;
        display: block;
        background-color: white;
        color: #321c3d;
        font-family: 'Roboto', sans-serif;
    }

    .dbrand {
        display: flex;
        align-items: center;
    }

    .dbrand h4 {
        margin: 0;
        padding-right: 1px;
    }

    .dbrand img {
        height: 1em;
    }

    .dslogan h1 {
        margin: 0;
    }

    .dshakeimgs {
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }

    .dshakeimgs img {
        width: 250px;
        height: 250px;
        max-width: 100%;
        height: auto;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    .dshakeimgs img:hover {
        transform: scale(1.05);
        opacity: 0.9;
    }
}

/* ------ */